import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const coffeefellowsCustomerGroup = 'coffee_fellows';
export const customerGroup = coffeefellowsCustomerGroup;

const supportEmail = {
	coffeefellows: 'coffeefellows@smoothr.de'
};
const firebaseConfig = {
	coffee_fellows: {
		apiKey: "AIzaSyBx3rlbR5y81d9sWT4DJEfDlqFyqUmXaz0",
		authDomain: "dev-env-216009.firebaseapp.com",
		databaseURL: "https://dev-env-216009.firebaseio.com",
		projectId: "dev-env-216009",
		storageBucket: "dev-env-216009.appspot.com",
		messagingSenderId: "1004449889395",
		appId: "1:1004449889395:web:1adcb6c58ef2aa2ae0fe06",
		measurementId: "G-1E1BZ92E46"
	  }
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyAfhF8avR6sxU3lqtjLW0dfC210CqcbaFE',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['de']
};

import 'zone.js/dist/zone-error';
