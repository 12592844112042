import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import {getPrice, getSlotsByOrder} from '../../../smoothr-web-app-core/utils/utils';
import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {IonContent, IonSlides, ModalController, PickerController} from '@ionic/angular';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import * as moment from 'moment';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import validator from 'validator';
import {TosPage} from '../../pages/tos/tos.page';
import {PrivacyPage} from '../../pages/privacy/privacy.page';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Moment} from 'moment';
import {ValidationUtils} from '../../../smoothr-web-app-core/utils/validation-utils';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {Router} from '@angular/router';
import {calculateGeoDistance, MapsUtils} from '../../../smoothr-web-app-core/utils/maps-utils';
import {sleep, isVenueOpen, numberToCurrency} from '../../../smoothr-web-app-core/utils/utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import {AnalyticsService} from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import {TimeUtils} from '../../../smoothr-web-app-core/utils/time-utils';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleOption from 'src/smoothr-web-app-core/models/ArticleOption';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import {environment} from 'src/environments/environment';
import {PhoneNumber, PhoneNumberUtil} from 'google-libphonenumber';
@Component({
	selector: 'app-checkout-modal',
	templateUrl: './checkout-modal.component.html',
	styleUrls: ['checkout-modal.component.scss']
})
export class CheckoutModalComponent extends RepositoryDirective implements OnInit {
	orderUtils = OrderUtils;
	loading = false;
	now: moment.Moment;
	pt = PreorderType;
	nameError: string;
	// emailError: string;
	phoneError: string;
	streetNumberError: string;
	orderAtError: string;
	streetError: string;
	postalCodeError: string;
	hadPostalCode = false;
	telCountryOptions: any = {
		cssClass: 'tel-country-popover'
	};
	isVenueOpen = false;
	tip: number;
	numberToCurrency = numberToCurrency;
	slots: Moment[] = [];
	environment = environment;

	@ViewChild(IonContent, {static: false}) content;
	@ViewChild(IonSlides, {static: false}) slides: IonSlides;
	slideOptions = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: false
	};
	isOverviewPage = false;
	constructor(
		private modalCtrl: ModalController,
		protected repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private pickerCtrl: PickerController,
		private translate: TranslateService,
		private router: Router,
		private analytics: AnalyticsService,
		private cdr: ChangeDetectorRef
	) {
		super(repository);
		if (this.address && this.address.postalCode && this.address.postalCode.length > 0) {
			this.hadPostalCode = true;
		}
		if (this.order && this.order.preorder && this.order.preorder.postalCode && this.address.postalCode.length > 0) {
			this.hadPostalCode = true;
		}
	}

	static async show(modalCtrl: ModalController, analytics: AnalyticsService, order: Order, tip: number) {
		const modal = await modalCtrl.create({
			component: CheckoutModalComponent,
			componentProps: {
				tip
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		analytics.beginCheckout(order);
		return await modal.onDidDismiss();
	}

	ngOnInit() {
		super.ngOnInit();
		this.analytics.checkoutProgress(this.order, null, 1);
		this.analytics.customerBeginsCheckout();
		this.loadSlots();
	}

	onOrder() {
		super.onOrder();
		const postalCodeInOrder =
			this.order && this.order.preorder && this.order.preorder.postalCode && this.order.preorder.postalCode.length > 0;
		if (this.order && this.order.preorder) {
			if (this.repository._previousPreorder) {
				if (!this.order.preorder.name) {
					this.order.preorder.email = this.repository._previousPreorder.name;
				}
				if (!this.order.preorder.email) {
					this.order.preorder.email = this.repository._previousPreorder.email;
				}
				if (!this.order.preorder.phone) {
					this.order.preorder.phone = this.repository._previousPreorder.phone;
				}
				if (!this.order.preorder.companyName) {
					this.order.preorder.companyName = this.repository._previousPreorder.companyName;
				}
				if (!this.order.preorder.floor) {
					this.order.preorder.floor = this.repository._previousPreorder.floor;
				}
				if (!this.order.preorder.subcard) {
					this.order.preorder.subcard = this.repository._previousPreorder.subcard;
				}
			}
		}
		this.hadPostalCode = this.hadPostalCode || postalCodeInOrder;
		this.cdr.detectChanges();
	}

	onAddress() {
		super.onAddress();
		this.hadPostalCode =
			this.hadPostalCode || (this.address && this.address.postalCode && this.address.postalCode.length > 0);
		this.reloadAddress();
	}

	onCustomer() {
		super.onCustomer();
		if (this.customer) {
			if (this.customer.postalCode && this.customer.postalCode.length > 0) {
				this.hadPostalCode = true;
			}
			// if (!this.order.preorder.email) {
			// 	this.order.preorder.email = this.customer.email;
			// }
			if (!this.order.preorder.name) {
				this.order.preorder.name = this.customer.name;
			}
			if (!this.order.preorder.companyName) {
				this.order.preorder.companyName = this.customer.companyName;
			}
			if (!this.order.preorder.phone) {
				this.order.preorder.phone = this.customer.phone;
			}
			if (!this.order.preorder.floor) {
				this.order.preorder.floor = this.customer.floor;
			}
			if (!this.order.preorder.subcard) {
				this.order.preorder.subcard = this.customer.subcard;
			}
			if (this.order.preorder.type === PreorderType.DELIVERY) {
				if (!this.order.preorder.street) {
					this.order.preorder.street = this.customer.street;
				}
				if (!this.order.preorder.postalCode) {
					this.order.preorder.postalCode = this.customer.postalCode;
				}
				if (!this.order.preorder.city) {
					this.order.preorder.city = this.customer.city;
				}
				if (!this.order.preorder.number && this.order.preorder.street === this.customer.street) {
					this.order.preorder.number = this.customer.number;
				}
			}
		}
	}

	async validate(): Promise<boolean> {
		this.nameError = null;
		// this.emailError = null;
		this.phoneError = null;
		this.orderAtError = null;
		this.streetNumberError = null;
		this.postalCodeError = null;
		this.streetError = null;

		if (
			(OrderUtils.isInside(this.order) || OrderUtils.isTakeAway(this.order)) &&
			this.isVenueOpen &&
			!this.order.orderAt
		) {
			this.order.asap = true;
		}
		if (!this.venue || !this.order || !this.order.preorder) {
			this.snackbarCtrl.open(this.translate.instant('errors.data_error'), null, {
				duration: 2000
			});
			return false;
		}
		// if (!this.order.preorder.name || this.order.preorder.name.length < 3) {
		// 	this.nameError = this.translate.instant('errors.name');
		// 	this.snackbarCtrl.open(this.nameError, null, {
		// 		duration: 2000
		// 	});
		// 	return false;
		// }
		// if (!this.order.preorder.email || !validator.isEmail(this.order.preorder.email)) {
		// 	this.emailError = this.translate.instant('errors.email');
		// 	this.snackbarCtrl.open(this.emailError, null, {
		// 		duration: 2000
		// 	});
		// 	return false;
		// }
		// this.phoneError = this.validatePhone(this.order.preorder.phone);
		// console.log(this.phoneError);
		// if (this.phoneError) {
		// 	this.snackbarCtrl.open(this.phoneError, null, {
		// 		duration: 2000
		// 	});
		// 	return false;
		// }

		// Address was changed in checkout modal check if venue delivers to this new address
		// new address needs to be checked if its in the delivery range
		if (OrderUtils.isDelivery(this.order) && this.order.preorder.street !== this.address.street) {
			this.order.preorder.street = this.order.preorder.street.trim();
			this.order.preorder.number = this.order.preorder.number.trim();
			if (this.order.preorder.street === '') {
				this.streetError = this.translate.instant('address.street');
				this.snackbarCtrl.open(this.streetError, null, {
					duration: 2000
				});
				return false;
			}
			try {
				const pred =
					this.order.preorder.street +
					' ' +
					this.order.preorder.number +
					', ' +
					this.order.preorder.city +
					' ' +
					this.order.preorder.postalCode;
				const geocode = await MapsUtils.getPlace(pred);
				const address = MapsUtils.placeToAddress(geocode);
				const addressValidationResult = ValidationUtils.validateAddress(address, true);
				if (addressValidationResult) {
					this.streetError = this.translate.instant(addressValidationResult);
					return false;
				}
				const delivers =
					(this.venue.deliveryByRadius &&
						calculateGeoDistance(
							address.lat,
							address.lng,
							this.venue.location.coordinates[1],
							this.venue.location.coordinates[0]
						) <= this.venue.deliveryRadius) ||
					(this.venue.isPostalDelivery && this.venue.deliveryPostalCodes.indexOf(address.postalCode));
				if (!delivers) {
					this.streetError = this.translate.instant('errors.street_delivery');
					this.snackbarCtrl.open(this.streetError, null, {
						duration: 2000
					});
					return false;
				}
				this.repository.address.emit(address);
				this.order.preorder.street = this.address.street;
			} catch (e) {
				this.streetError = this.translate.instant('errors.street_not_found');
				this.snackbarCtrl.open(this.streetError, null, {
					duration: 2000
				});
				return false;
			}
		}

		if (
			this.order.preorder.type === PreorderType.DELIVERY &&
			(!this.order.preorder.number || this.order.preorder.number.length < 1)
		) {
			this.streetNumberError = this.translate.instant('errors.street_number');
			this.snackbarCtrl.open(this.streetNumberError, null, {
				duration: 2000
			});
			return false;
		}
		if (
			this.order.preorder.type === PreorderType.DELIVERY &&
			(!this.order.preorder.postalCode || this.order.preorder.postalCode.length < 1)
		) {
			this.postalCodeError = this.translate.instant('errors.postal');
			this.snackbarCtrl.open(this.postalCodeError, null, {
				duration: 2000
			});
			return false;
		}
		if (!this.order.orderAt || moment(this.order.orderAt).isBefore(moment())) {
			if (!OrderUtils.isInside(this.order) && !this.order.asap) {
				this.orderAtError = this.translate.instant('errors.order_at');
				this.order.orderAt = null;
				this.snackbarCtrl.open(this.orderAtError, null, {
					duration: 2000
				});
				return false;
			}
		}
		return true;
	}
	// validatePhone(orderPhone): string {
	// 	const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
	// 	try {
	// 		if (orderPhone.slice(0, 2) === '00') {
	// 			orderPhone = orderPhone.replace(/^.{2}/g, '+');
	// 			this.order.preorder.phone = orderPhone;
	// 		}
	// 		const phoneNumber = phoneUtil.parse(orderPhone);
	// 		if(phoneUtil.isValidNumber(phoneNumber) && phoneUtil.isPossibleNumber(phoneNumber)) {
	// 			return '';
	// 		} else {
	// 			return this.translate.instant('errors.phone')
	// 		}
	// 	} catch (e) {
	// 		return this.translate.instant('errors.phoneCountry');
	// 	}
	// }
	async openTimeSheet() {
		if (!this.venue) {
			this.snackbarCtrl.open(this.translate.instant('errors.venue'), null, {
				duration: 2000
			});
			return;
		}
		if (this.loading) {
			return;
		}
		this.loading = true;
		try {
			let slots = await getSlotsByOrder(this.venue, this.order);
			if (slots.length === 0) {
				this.loading = false;
				this.snackbarCtrl.open(this.translate.instant('checkout_modal.no_slots'), null, {
					duration: 2000
				});
				return;
			}
			if (this.isVenueOpen && (OrderUtils.isInside(this.order) || OrderUtils.isTakeAway(this.order))) {
				slots = [null, ...slots];
			}
			const options = {
				columns: [
					{
						name: 'slot',
						class: 'time-picker',
						options: slots.map(slot => {
							return {
								text: this.slotToText(slot ? moment(slot.time) : null),
								value: slot
							};
						})
					}
				],
				buttons: [
					{
						text: this.translate.instant('checkout_modal.cancel'),
						role: 'cancel'
					},
					{
						text: this.translate.instant('checkout_modal.confirm'),
						handler: value => {
							const slot = value.slot.value;
							if (!slot) {
								this.order.asap = true;
								this.order.orderAt = null;
								return;
							}
							const conflictingArticles = OrderUtils.slotConflictingArticlesInOrder(moment(slot.time), this.order);
							if (conflictingArticles.length > 0) {
								this.snackbarCtrl.open(
									this.translate.instant('checkout_modal.products_unavailable') +
										conflictingArticles
											.map(conflictingArticle => conflictingArticle.article.name.de)
											.reduce((previousValue, currentValue) => previousValue + ' ' + currentValue),
									null,
									{
										duration: 3000
									}
								);
								return;
							}
							this.order.orderAt = moment(slot.time).local();
						}
					}
				]
			};
			const picker = await this.pickerCtrl.create(options);

			await picker.present();
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('checkout_modal.time_error'), null, {
				duration: 2000
			});
			console.error(e);
		}
		this.loading = false;
	}

	async close() {
		if (!(await this.validate())) {
			await sleep(100);
			const firstErrorElement: HTMLElement = [...this.content.el.children].find(el => {
				return el.className.split(' ').findIndex(clazz => clazz === 'input-error') >= 0;
			});
			if (firstErrorElement !== undefined) {
				await this.content.scrollToPoint(0, firstErrorElement.offsetTop, 300);
			}
			return;
		}
		const data = {
			preorder: this.order.preorder,
			orderAt: this.order.orderAt,
			asap: this.order.asap,
			tip: this.tip
		};
		this.repository.previousPreorder.emit(this.order.preorder);
		await this.modalCtrl.dismiss(data);
	}

	async dismiss() {
		await this.modalCtrl.dismiss();
	}

	reloadAddress() {
		if (this.order && this.order.preorder && this.address && this.order.preorder.type === PreorderType.DELIVERY) {
			this.order.preorder.city = this.address.city;
			this.order.preorder.street = this.address.street;
			if (this.address.postalCode && this.address.postalCode.length > 0) {
				this.order.preorder.postalCode = this.address.postalCode;
			}
			this.order.preorder.country = this.address.country;
			this.order.preorder.number = this.address.number;
		}
	}

	async openTos() {
		window.open(TosPage.url, '_blank');
	}

	async openPrivacy() {
		window.open(PrivacyPage.url, '_blank');
	}

	slotToText(slot: Moment): string {
		const asapPossible = (OrderUtils.isInside(this.order) || OrderUtils.isTakeAway(this.order)) && this.isVenueOpen;
		if (!slot) {
			return this.translate.instant(asapPossible ? 'checkout_modal.first_slot' : 'checkout_modal.choose_slot');
		}
		const slotMoment = moment(slot);
		const sameDay = TimeUtils.isSameDate(slotMoment, moment());
		if (sameDay) {
			return slotMoment.format('HH:mm');
		} else {
			return slotMoment.format('DD.MM. HH:mm');
		}
	}

	onVenue() {
		this.isVenueOpen = isVenueOpen(this.venue);
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.isVenueOpen = false;
		if (this.order) {
			this.order.asap = false;
		}
	}

	hiddenInCart(article: Article): boolean {
		return article.tags && article.tags.find(tag => tag.identifier === 'hide_cart') !== undefined;
	}

	async onButtonClick() {
		if (!(await this.validate())) {
			return;
		}
		if (
			this.order &&
			this.order.preorder.type === PreorderType.INSIDE &&
			this.order?.tableNumber !== 'preorder_table'
		) {
			await this.close();
			return;
		}
		if (!this.isOverviewPage) {
			await this.toOverview();
		} else {
			await this.close();
		}
	}
	async toOverview() {
		const result = await this.validate();
		if (!result) {
			this.isOverviewPage = false;
			return;
		}
		await this.slides.update();
		await this.slides.slideNext();
		console.log('Slide next');
		this.isOverviewPage = true;
	}
	async editPersonalData() {
		await this.slides.slideTo(0);
		this.isOverviewPage = false;
	}
	slotSelected($event) {
		this.order.orderAt = $event;
		if (!$event) {
			this.order.asap = true;
		} else {
			this.order.asap = false;
		}
	}
	async backOrDismiss() {
		if (this.isOverviewPage) {
			await this.editPersonalData();
		} else {
			await this.dismiss();
		}
	}
	async loadSlots() {
		this.slots = [];
		this.order.orderAt = null;
		if (!this.venue) {
			this.snackbarCtrl.open(this.translate.instant('errors.venue'), null, {
				duration: 2000
			});
			return;
		}
		if (this.loading) {
			return;
		}
		this.loading = true;
		try {
			this.slots = (await getSlotsByOrder(this.venue, this.order)).map(slot => moment(slot.time));
			if (OrderUtils.isInside(this.order) || OrderUtils.isTakeAway(this.order)) {
				this.slots = [null, ...this.slots];
			}
			if (this.slots.length === 0) {
				this.loading = false;
				this.snackbarCtrl.open(this.translate.instant('checkout_modal.no_slots'), null, {
					duration: 2000
				});
				return;
			}
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('checkout_modal.time_error'), null, {
				duration: 2000
			});
			console.error(e);
		}
		this.loading = false;
	}
	priceOfOption(articleGroup: ArticleGroup, articleOption: ArticleOption): number {
		const price = getPrice(articleOption.article, OrderType.PREORDER, this.order.preorder.type);
		if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && articleGroup.isPromo) {
			return 0;
		}
		return price * articleOption.quantity;
	}
	changeTip(event) {
		console.log(event);
		this.tip = event;
	}
}
