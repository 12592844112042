import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const coffeefellowsCustomerGroup = 'coffee_fellows';
export const customerGroup = coffeefellowsCustomerGroup;

const supportEmail = {
	coffeefellows: 'coffeefellows@smoothr.de'
};
const firebaseConfig = {
	coffee_fellows: {
		apiKey: "AIzaSyB1Y4wG6BoFE2hi8irI30ofKfscXoEPPDw",
		authDomain: "prod-env-smoothr.firebaseapp.com",
		databaseURL: "https://prod-env-smoothr.firebaseio.com",
		projectId: "prod-env-smoothr",
		storageBucket: "prod-env-smoothr.appspot.com",
		messagingSenderId: "640058019537",
		appId: "1:640058019537:web:565e91836f6ee6b2cbfff0",
		measurementId: "G-1C7H2ZQP8D"
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyBNHEl-JX2GFLRd0sUqDm6hFvVr1uKIRu4',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
	countryList: ['de']
};
