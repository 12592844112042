import {TranslateService} from '@ngx-translate/core';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {ActivatedRoute, Router} from '@angular/router';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {sleep, venueAcceptsOrders} from '../../../smoothr-web-app-core/utils/utils';
import {MenuPage} from '../menu/menu.page';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { MatSnackBar } from '@angular/material/snack-bar';

enum Language {
	GreatBritain = 'en',
	German = 'de'
}

@Component({
	selector: 'app-home',
	templateUrl: './home.page.coffee_fellows.html',
	styleUrls: ['./home.page.coffee_fellows.scss']
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	static shopUrl = 'venue/:venueShortId';
	static scanUrl = ':venueId/table/:tableNumber';
	environment = environment;
	languageEnum = Language;

	loading = false;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	active: any = {};

	constructor(protected repository: RepositoryService, private router: Router, public translate: TranslateService, private route: ActivatedRoute, private snackbarCtrl: MatSnackBar, private cdr: ChangeDetectorRef) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		await router.navigateByUrl(HomePage.url);
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
	}

	ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		this.loadVenues();
		this.route.paramMap.subscribe((params) => {
			if( params && params.get('venueShortId')) {
				this.loadVenue(params.get('venueShortId'))
				return;
			}
			if (params && params.get('tableNumber') && params.get('venueId')) {
				this.loadVenueByQrCode(params.get('tableNumber'), params.get('venueId'))
			}
		});
	}

	ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.order.emit(null);
		}
		this.repository.verifiedOrder.emit(null);
	}

	async locateAndShowStores(preorderType: PreorderType) {
		this.loading = true;
		// const venue = (await this.repository.getAllVenues()).find(v => v.readableId === "cf1")
		const venue = (await this.repository.getAllVenues()).find(ven => venueAcceptsOrders(ven, preorderType));
		console.log(venue)

		if (!venue) {
			console.log('No available venues');
			this.loading = false;
			this.snackbarCtrl.open(this.translate.instant('home_page.service_error'), null, {
				duration: 2000
			})
			return;
		}
		await this.loadVenueAndCreateOrder(venue, null, preorderType);
	}

	async loadVenueAndCreateOrder(venue: Venue, address: Address, preorderType: PreorderType) {
		console.log('loadVenueAndCreateOrder(', venue, address, preorderType, ')');
		const loadedVenue = await this.repository.getVenue(venue._id);
		this.repository.createOrder(loadedVenue, address, preorderType);
		await MenuPage.navigate(this.router);
		this.loading = false;
	}

	loadVenues() {
		for (const preorderType of this.preorderTypes) {
			this.active[preorderType] = false;
		}
		new Promise<void>(async (resolve, reject) => {
			try {
				const venues = await this.repository.getAllVenues();
				console.log(venues);
				for (const venue of venues) {
					for (const preorderType of this.preorderTypes) {
						console.log(venue);
						this.active[preorderType] = this.active[preorderType] || venueAcceptsOrders(venue, preorderType);
					}
				}
				resolve();
			} catch (e) {
				reject(e);
			}
		}).finally(() => (this.loading = false));
	}
	async goToMenu(type: PreorderType) {
		this.locateAndShowStores(type);
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
	async loadVenue(venueId: string) {
		try {
			this.loading = true;
			await this.repository.getVenue(venueId);
			return;
		} catch(e) {
			console.log(e)
		}
 	}
	async loadVenueByQrCode(tableNumber: string, venueId: string) {
		this.cdr.detectChanges();
		try {
			this.loading = true;
			const venueData = await this.repository.getVenue(venueId);
			console.log()
			if(venueAcceptsOrders(venueData, PreorderType.INSIDE)) {
				this.loading = true;
				const table = (await Api.getTable(this.venue._id, tableNumber)).data;
				await sleep(100);
				if(venueData && table) {
					this.repository.venue.emit(venueData);
					this.repository.createOrder(this.venue, null, PreorderType.INSIDE);
					this.order.tableNumber = table.number;
					this.order.table = table._id;
					this.repository.order.emit(this.order);
					sleep(200);
					await MenuPage.navigate(this.router);
					return;
				}
				this.cdr.detectChanges();
				this.loading = false;
			}
			this.snackbarCtrl.open(this.translate.instant('home_page.service_error'), null, {
				duration: 2000
			});
			return;
		} catch(e) {
			this.snackbarCtrl.open(this.translate.instant('home_page.service_error'), null, {
				duration: 2000
			})
			this.loading = false;
		}
	}
}
